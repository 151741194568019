/* eslint camelcase: "off", object-shorthand: "off", react/jsx-wrap-multilines: "off" */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { InventoryCarousel } from 'reaxl-organisms';
import { clickTypes } from 'reaxl-analytics';
import axios from 'axios';
import { useAnalytics } from 'reaxl-analytics';
import { atcBaseUrl } from '../../constants/config.js';

const owner = { name: 'tester' };
const savedListingIds = [];
const convertData = (car) => {
  const { vdp_heading, images, vdp_url, price, mileage, cs_listing_id, model, make, vin, year, trim, lsc_body_styles, lsc_owner_id } = car;
  const newListing = {
    title: vdp_heading,
    ownerId: lsc_owner_id,
    id: cs_listing_id,
    year,
    mileage,
    model,
    make,
    trim,
    vin,
    style: lsc_body_styles && lsc_body_styles.length ? lsc_body_styles[0].name.toLowerCase() : '',
    images: {
      primary: 0,
      sources: [],
    },
    website: {
      href: vdp_url,
    },
    pricingDetail: {
      discounts: [],
      salePrice: price,
    },
  };
  // setting the image
  if (images && images.length) {
    for (const image of images) {
      const imageSourceObject = {
        alt: vdp_heading,
        src: '',
      };
      imageSourceObject.src = image.url;
      newListing.images.sources.push(imageSourceObject);
    }
  }
  return newListing;
};
export default function PopularVehiclesSection() {
  const [carListings, setCarListings] = useState([]);
  const [listingsLoaded, setListingsLoaded] = useState(false);
  const { sendClick, sendImpressions } = useAnalytics();
  const ref = useRef();
  const [isOnScreen, setIsOnScreen] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isInitialTagFire, setIsInitialTagFire] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [initialIndex, setInitialIndex] = useState(0);
  const [initialCells, setInitialCells] = useState([]);
  const onShopAllPrivateSellerCarsClick = (event) => {
    sendClick('shopAllPrivateSellerCarsClick', event);
  };
  const handleInventoryListingClick = useCallback(async (event, selectedListing) => {
    event.stopPropagation();
    const { id, mileage, ownerId, year, make, model, trim, pricingDetail, style } = selectedListing;
    const metadata = {
      data: {
        inventoryId: selectedListing.id,
        eventSource: 'vehicle',
        par: 'pvt_v_lstg_',
        pixallData: {
          eventSource: 'vehicle',
          impressionType: 'pvt',
          label: 'pvt_v_lstg_im',
          listingType: 'pvt',
        },
      },
      // refer to inventoryTransformer.js to see why information is being put in this format
      state: {
        inventory: {
          [id]: {
            id,
            mileage,
            year,
            make,
            makeCode: make,
            model,
            modelCode: model,
            trim,
            trimCode: trim,
            style: style,
            listingType: 'private-seller',
            type: 'used',
            pricingDetail,
            owner: {
              id: ownerId,
              privateSeller: true,
            },
          },
        },
      },
    };
    sendClick('inventoryClick', event, metadata);
  }, [sendClick]);
  const handleSendImpressions = useCallback((index, selectedListingsIds, startingIndex, state) => sendImpressions({
    name: 'inventoryImpressions',
    state: state,
    data: {
      cmp: 'pvt_v_lstg_',
      selectedItemIds: selectedListingsIds,
      pixallData: {
        impressionType: 'pvt',
        label: 'pvt_v_lstg',
        listingType: 'pvt',
      },
      startingIndex,
    },
  }), [sendImpressions]);
  const handleSettle = useCallback((index, _direction, _width, _selectedCells, selectedIndices) => {
    sendClick('atPrivateSellerCarouselNavigationClick', undefined, { type: _direction === 'left' ? 'previous' : 'next' });
    const selectedListings = (selectedIndices && selectedIndices.length) ? carListings.slice(selectedIndices[0], selectedIndices[selectedIndices.length - 1] + 1) : [];
    const selectedListingsIds = selectedListings.map(({ id }) => id);
    const state = {
      inventory: {},
    };
    // refer to getInventoryOwnerById.js, this should dig into the last case
    for (const listing of selectedListings) {
      state.inventory[listing.id] = {
        id: listing.id,
        listingType: 'private-seller',
        owner: {
          id: listing.ownerId,
        },
      };
    }
    handleSendImpressions(index, selectedListingsIds, selectedIndices[0], state);
  }, [handleSendImpressions, carListings]);
  const handleReady = (index, selectedCells) => {
    setInitialIndex(index);
    setInitialCells(selectedCells);
    setIsReady(true);
  };
  const handleInitialView = useCallback((index, selectedCells) => {
    const selectedCellsLength = selectedCells && selectedCells?.length ? selectedCells?.length : 0;
    const selectedListings = selectedCells && selectedCells.length ? carListings.slice(index, (index + selectedCells?.length)) : [];
    const selectedListingsIds = selectedListings.map(({ id }) => id);
    const state = {
      inventory: {},
    };
    // refer to getInventoryOwnerById.js, this should dig into the last case
    for (const listing of selectedListings) {
      state.inventory[listing.id] = {
        id: listing.id,
        mileage: listing.mileage,
        year: listing.year,
        make: listing.make,
        makeCode: listing.make,
        model: listing.model,
        modelCode: listing.model,
        trim: listing.trim,
        trimCode: listing.trim,
        style: listing.style,
        listingType: 'private-seller',
        type: 'used',
        pricingDetail: listing.pricingDetail,
        owner: {
          id: listing.ownerId,
          privateSeller: true,
        },
      };
    }
    handleSendImpressions(index, selectedListingsIds, selectedCellsLength * index, state);
  }, [handleSendImpressions, carListings]);
  const carouselSettings = { onReady: handleReady };
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsOnScreen(entry.isIntersecting);
    });
    observer.observe(ref.current);
    if (isInitialRender) {
      setIsInitialRender(false);
      axios
        .get('/api/psx-buy/recent-listings')
        .then((response) => {
          const cars = response.data?.result?.cars;
          const newListings = [];
          if (cars) {
            for (const car of cars) {
              const newListing = convertData(car);
              newListings.push(newListing);
            }
          }
          setCarListings(newListings);
          if (cars) {
            setListingsLoaded(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (isOnScreen && isReady) {
      if (isInitialTagFire) {
        setIsInitialTagFire(false);
        handleInitialView(initialIndex, initialCells);
      }
    }
    return () => {
      observer.disconnect();
    };
  }, [ref, isOnScreen, isReady]); // dependency array: tells react to run useEffect whenever one of these variables changes
  return (
    <div
      className="buyer-landing-popular-vehicles"
      ref={ref}
    >
      <div className="popular-vehicles-content">
        <div className="popular-vehicles-text">
          <h3>Recently Listed Vehicles for Sale By Owner</h3>
          <div className="all-link">
            <a
              href={atcBaseUrl + '/cars-for-sale/by-owner'}
              onClick={(event) => onShopAllPrivateSellerCarsClick(event)}
            >
              Shop All Private Seller Cars&nbsp;
              <span className="glyphicon glyphicon-menu-right" />
            </a>
          </div>
        </div>
        {
          listingsLoaded
          && <InventoryCarousel
            data-testid="inventory-carousel"
            listings={carListings}
            clickType={clickTypes.SIMILAR_INVENTORY}
            carouselSettings={carouselSettings}
            onSettle={handleSettle}
            owner={owner}
            onInventoryListingClick={handleInventoryListingClick}
            savedListingIds={savedListingIds}
            showFooter
          />
        }
      </div>
    </div>
  );
}
