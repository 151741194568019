/* eslint-disable react/prop-types */
import React from 'react';
import AtcHeader from '../components/AtcHeader';
import FooterContainer from './AtcFooter';
// Base Container.
export default function BaseContainer({
  Component,
  ...pageProps
}) {
  return (
    <>
      <AtcHeader />
      <Component {...pageProps} />
      <FooterContainer />
    </>
  );
}
